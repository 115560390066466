import axios from 'axios';

const EVENT = {
    EVENT: 'EVENT',
    CLEAR_EVENT: 'EVENTS_EVENT_CLEAR',
    IS_PENDING: 'IS_PENDING_EVENT',
    GET_EVENT: 'GET_EVENT',
    GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
    GET_EVENT_ERROR: 'GET_EVENT_ERROR',
    CLEAR_EVENT: 'CLEAR_EVENT'

};

const initialState = {};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT.IS_PENDING:
            return {
                ...state,
                isPending: true,
            };
        case EVENT.CLEAR_EVENT: 
            return {
                ...state,
                current_event: {}
            }
        case EVENT.EVENT:
            return {
                ...state,
                event: action.payload,
                isPending: false,
            };
        case EVENT.GET_EVENT:
            return {
                ...state,
                current_event: {},
                current_event_isPending: true,
            };
        case EVENT.GET_EVENT_SUCCESS:
            return {
                ...state,
                current_event: action.payload,
                current_event_isPending: false,
            };
        case EVENT.GET_EVENT_ERROR:
            return {
                ...state,
                current_event: {},
                current_event_isPending: false,
            };
        case EVENT.CLEAR_EVENT:
            const {event, ...newState} = state;
            return newState;
        default:
            return state;
    }
};

export default eventReducer;

/* Загрузка события (оповещения) */

export const get_current_event = (eventId) => dispatch => {
    dispatch({
        type: EVENT.GET_EVENT,
    });
    axios.get(`/api/statistics/event-storage/event/${eventId}/`)
    .then(res => {
        dispatch({
            type: EVENT.GET_EVENT_SUCCESS,
            payload: res.data
        });
    }).catch(() => {
        dispatch({
            type: EVENT.GET_EVENT_ERROR,
        });
    })
}

export const loadEvent = (eventId) => dispatch => {
    dispatch({
        type: EVENT.IS_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/event-storage/event/${eventId}/`)
        .then(res => {
            dispatch({
                type: EVENT.EVENT,
                payload: res.data
            });
        })
};

export const clearEvent = () => dispatch => {
    dispatch({
        type: EVENT.CLEAR_EVENT,
        payload: null,
    });
};

/* Подтверждение события */
export const updateEvent = (eventId, hasViolation, userId, personId) => dispatch => {
    dispatch({
        type: EVENT.IS_PENDING,
        payload: null,
    });
    axios.put(`/api/statistics/event-storage/event/${eventId}/`, {
        event_id: eventId,
        user_id: userId,
        confirmation_status: hasViolation,
        person_id: personId
    })
        .then(res => {
            dispatch({
                type: EVENT.EVENT,
                payload: res.data
            });
        })
};
