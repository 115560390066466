import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {hasValidRole} from '../utils/utils';
import {PAGES_AVAILABLE} from '../roles';

const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
const AUTH_LOGIN_FETCH = 'AUTH_LOGIN_FETCH';
const AUTH_NEW_TOKEN = 'AUTH_NEW_TOKEN';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const SWITCH_ROLE = 'SWITCH_ROLE';
const AUTH_REDIRECT_TO = 'AUTH_REDIRECT_TO';

const initialState = {
    accessToken: null,
    roles: [],
    role: 'operator',
};

const AuthReducer = (state = initialState, action) => {
    switch(action.type) {
        case AUTH_REDIRECT_TO:
        case AUTH_LOGIN_FETCH:
        case AUTH_LOGIN_ERROR:
            return {
                ...initialState,
                ...action.payload
            };
        case AUTH_NEW_TOKEN:
        case SWITCH_ROLE:
            return {
                ...state,
                ...action.payload
            };
        case AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default AuthReducer;

export const onLogin = (username, password) => dispatch =>
    axios.post('/api/statistics/auth/login/', {username, password})
        .then(res => {
            const meta = jwtDecode(res.data.access_token);
                if (!hasValidRole(meta)) {
                    dispatch({
                        type: AUTH_LOGIN_ERROR,
                        payload: {
                            isError: true,
                            errorText: 'Нет прав для работы в системе'
                        }
                    });
                    return;
                }
                dispatch(setUser(res.data.access_token, username, meta.roles, meta.roles[0], meta.user_id));
        })
        .catch(err => {
            console.log('err>', err);
            dispatch({
                type: AUTH_LOGIN_ERROR,
                payload: {
                    isError: true,
                    errorText: !err.response || err.response.status !== 401 && err.response.status !== 500 ?
                        'Неизвестная ошибка' : 'Неверная пара логин-пароль'
                }
            });
        });

export const setUser = (accessToken, username, roles, role, userId) => dispatch => {
    dispatch({
        type: AUTH_LOGIN_FETCH,
        payload: {
            isError: false,
            accessToken: accessToken,
            username,
            roles,
            role,
            userId,
        }
    });
};

export const refreshAccessToken = refreshToken => dispatch => {
    axios.post('/api/statistics/auth/refresh/', {refresh_token: refreshToken})
        .then(res => dispatch(setAccessToken(res.data.access_token)))
        .catch(err => {
            dispatch({
                type: AUTH_LOGIN_ERROR,
                payload: {
                    isError: true
                }
            });
        });
};

export const setAccessToken = accessToken => dispatch => {
    dispatch({
        type: AUTH_NEW_TOKEN,
        payload: {accessToken}
    })
};

export const onLogout = () => dispatch => {
    dispatch({
        type: AUTH_LOGOUT,
        payload: {}
    });
};

export const switchRole = role => dispatch => {
    dispatch({
        type: SWITCH_ROLE,
        payload: {role}
    })
    const userPages = PAGES_AVAILABLE[role];
    if (userPages && userPages[0]) {
        window.router.navigate(userPages[0])
    }
};

export const setRedirectTo = (redirectTo) => {
    return dispatch => dispatch({
        type: AUTH_REDIRECT_TO,
        payload: redirectTo
    });
}

